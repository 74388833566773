<div class="container pt-5"> 
  <div class="row mb-3 mt-5">
      <div class="col-xl-4">
                <!-- Card -->
                <div class="card nbQuest">
                  <div class="card-body">
                    <div class="row align-items-center">
                      <div class="col ">
           
                        <!-- Title -->
                        <h6 class="text-uppercase text-muted mb-2">
                           Nb de questions
                        </h6>
           
                   
                       <!-- Heading -->
                       <h3><span class="active choice" [ngClass]="{ 'active' : isActive }" (click)="isActive = true" (click)="nbQuestFunct(10); countdown.restart(); refreshTest($event);">10 </span> <span class="choice" [ngClass]="{ 'active' : !isActive }" (click)="isActive = false" (click)="nbQuestFunct(20);countdown.restart(); refreshTest($event);">20</span></h3>
           
                      </div>
                      <div class="col-auto">
           
                       <!-- Icon -->
                       <span class="ti-pencil-alt ti"></span>
         
                     </div>
           
              </div>
              </div>
              </div>
            </div>
            <div class="col-xl-4">
        <!-- Card -->
        <div class="card note">
  
          <div class="card-body">
            <div class="row align-items-center">
              <div class="col">
  
                <!-- Title -->
                <h6 class="text-uppercase text-muted mb-2">
                  Note
                </h6>
  
                <!-- Heading -->
                <h3>{{note}} / 20</h3>
  
              </div>
              <div class="col-auto">
  
                <!-- Icon -->
                <span class="ti-notepad ti"></span>
  
              </div>
              
            </div> <!-- / .row -->
  
          </div>
        </div>
</div>
        <div class="col-xl-4">
         <!-- Card -->
         <div class="card timer" [ngClass]="{'alert-danger  ' : soonTimeUp}">
          <div class="card-body">
            <div class="row align-items-center">
              <div class="col">
  
                <!-- Title -->
                <h6 class="text-uppercase text-muted mb-2">
                  Timer
                </h6>
  
                <!-- Heading -->
                <countdown  #countdown [config]="{leftTime:360 , demand: true,format: 'm : ss',  notify: [60] }" (event)="timesUp($event)"></countdown>
  
              </div>
              <div class="col-auto">
  
                <!-- Icon -->
                        <!-- Switch -->
            <div class="custom-control custom-switch">
              <input type="checkbox" (change)="startCountdown($event)" [checked]="checkedTimer" [disabled]="disableInput" class="custom-control-input" id="cardToggle" data-toggle="chart" data-target="#conversionsChart" data-trigger="change" data-action="add" data-dataset="1">
              <label class="custom-control-label" for="cardToggle"></label>
            </div>
  
              </div>
  
      </div>
      </div>
      </div>

  </div>
    </div>
      </div>
  

  
   

  
  <div class="header py-3">
    <div class="container">
      
      <div class="row justify-content-center">
        <div class="col-12 col-xl-8 text-center">
          <div [class.d-none]="!viewAble">
          <!-- Pretitle -->
          <h6 class="mb-4 text-uppercase text-muted">
            Partie 1 sur 2
          </h6>

          <!-- Title -->
          <h1 class="mb-3" >
            Trouver la définition des termes suivants.
          </h1>
          </div>
          <div [class.d-none]="viewAble">
          <!-- Pretitle -->
          <h6 class="mb-4 text-uppercase text-muted">
            Partie 2 sur 2
          </h6>

          <!-- Title -->
          <h1 class="mb-3" >
            Trouver les termes manquants.
          </h1>
          </div>


      
   
      </div>
   </div>
  </div>
  </div>

  <div class="container mb-2">
    <div class="row">
  <div class="text-center w-100">
    <button type="button" class="part-btn btn" (click)="viewAble = !viewAble" [disabled]="viewAble" >Partie 1</button>
    <button type="button" class="part-btn btn" (click)="viewAble = !viewAble" [disabled]="!viewAble" >Partie 2</button>
  </div>
  <hr>
</div></div>

  
    <div class="container text-center text-lg-left"> 
      <div class="row">
        <div class="col-md-12">
          <div class="card">
            <div class="card-body p-4">
 
          
  <form #myform="ngForm" [class.correction]="correction">
    <div *ngIf="!showLoader" class='text-center'><div class="loader-db-spinner"></div></div>
    <div class="testContent" *ngIf="showLoader" >
  <div #part1 class="step" [class.d-none]="!viewAble">




    <div *ngFor="let testItem of filtered1; let indexE = index">
      
            <app-itemtest  
            [testItem]="testItem" 
            [disableInput]="disableInput"  
            [part]="1" [indexE]="indexE" 
            [showResponse]="showResponse" 
            (note)='noteCounter($event)'
            [nbQuest]= 'nbQuest'
            ></app-itemtest>
    </div>
  

  </div>
  <div #part2 class="step" [class.d-none]="viewAble">
    
    <div  *ngFor="let testItem of filtered2; let indexE = index" >

          <app-itemtest  
          [testItem]="testItem" 
          [disableInput]="disableInput" 
          [part]="2"  
          [indexE]="indexE+nbQuest/2" 
          [showResponse]="showResponse" 
          (note)='noteCounter($event)'
          [nbQuest]= 'nbQuest'></app-itemtest>
          
    </div>
  </div>
</div>

   
  </form>

  </div>

  </div>
  <div class="submitTest text-center" *ngIf="showLoader"  >
    <button class="btn btn-lg btn-primary  m-1" (click)="open(content)">Corriger le test</button>
    <button class="btn btn-lg btn-secondary  m-1" href="#" role="button" [routerLink]="['/test']" (click)="countdown.restart(); refreshTest($event); " >Relancer un test</button>
</div>
  </div>
  </div>


</div>
<ng-template #content let-modal>
  <div class="modal-header">
    <h4 class="modal-title">{{messageFin}}</h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body text-center">
    <h2>{{note}} / 20</h2>
    <p class="commentaire">{{commentaire}}</p>
  </div>

</ng-template>



