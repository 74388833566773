import { Component } from '@angular/core';
import * as firebase from 'firebase';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'termino';
  public isMenuCollapsed = true;
  constructor(){
    var config = {
      apiKey: "AIzaSyDkkRsuJpZWCwz0nopu2AmhYz4uUP83TfE",
      authDomain: "termino-angular.firebaseapp.com",
      databaseURL: "https://termino-angular.firebaseio.com",
      projectId: "termino-angular",
      storageBucket: "termino-angular.appspot.com",
      messagingSenderId: "308972141076",
      appId: "1:308972141076:web:b9abffb370b55392ee0501"
    };
    // Initialize Firebase
    firebase.initializeApp(config);
  }
}
