
<div class="container text-center text-lg-left pt-5"> 
      <div class="row mb-3 mt-5">
        <div class="col-xl-12">
          <div class="header mb-4 text-center">
  
                  <div [class.d-none]="!viewAble"></div>
        
                  <!-- Title -->
                  <h1 class="mb-4" >
                      Les définitions à apprendre par coeur.
                  </h1>
                    
           <div class="search-hero my-2 text-center">
            <input placeholder='Search...' class='search-input' type="text"  type="text" name="search" [(ngModel)]="searchText" (keyup)="firstPage($event)" autocomplete="off" placeholder="Rechercher">
            <i class="ti ti-search"></i>
          </div>
          </div>
          
          <div class="text-center" *ngIf="!showLoader"><div class="loader-db-spinner"></div>  </div>
        </div>

        
    
    <div  class="col-xl-12">
    <div class="item-course" *ngFor="let coursItem of coursItems | filter:searchText| paginate: { itemsPerPage: 8, currentPage: p }; let indexE = index">
      <!-- Card -->
      
        <div class="card">
 
         <div class="card-body">
  
      
            <app-itemcourse *ngIf="showLoader" [coursItem]="coursItem"  [indexE]="indexE" ></app-itemcourse>

            
        </div>
        </div>
      </div>
    </div>
       
            
      </div>
      
      

<pagination-controls class="text-center" (pageChange)="p = $event" previousLabel="Précédent"
nextLabel="Suivant"></pagination-controls>

</div>




