<nav class="navbar navbar-expand-sm navbar-light main-nav" id="mainNav">
  <div class="container">
    <a class="navbar-brand" routerLink="home" role="button">T</a>
    <button class="navbar-toggler" type="button" (click)="isMenuCollapsed = !isMenuCollapsed">
      &#9776;
    </button>
    <div [ngbCollapse]="isMenuCollapsed"  class="collapse navbar-collapse align-items-center justify-content-center order-2" id="navbarResponsive">
      <ul class="navbar-nav align-items-center">
        <li class="nav-item">
          <a routerLink="home" class="nav-link" (click)="isMenuCollapsed = true" role="button" routerLinkActive="active">Home</a>
        </li>
        <li class="nav-item">
          <a routerLink="cours" class="nav-link" (click)="isMenuCollapsed = true"  role="button" routerLinkActive="active">Cours</a>
        </li>
        <li class="nav-item">
          <a routerLink="test" class="nav-link" (click)="isMenuCollapsed = true"  role="button" routerLinkActive="active">Test</a>         
        </li>
      </ul>
    </div>

  </div>
</nav>

<router-outlet></router-outlet>

<footer class="mt-3">
  <div class="container">
  <nav class="navbar navbar-expand-lg navbar-light">

      <div class=" align-items-center">
        <ul class="navbar-nav align-items-center">
          <li class="nav-item mr-2">
           Ahmed B.
          </li>
          <li class="nav-item">
            <a class="nav-link" role="button" target="_blank" href="https://ahmed-bouedja.fr/">Mon site</a>
          </li>
          <li class="nav-item">
            <a routerLink="mentions-legales" class="nav-link" role="button" routerLinkActive="active" >Mentions Légales</a>         
          </li>
        </ul>
      </div>
  </nav>

</div>
</footer>