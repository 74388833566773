import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';
 
import { AngularFireModule } from '@angular/fire';
import { HttpClientModule } from '@angular/common/http'
import { CountdownModule } from 'ngx-countdown';
import { Ng2SearchPipeModule } from 'ng2-search-filter';
import {NgxPaginationModule} from 'ngx-pagination';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { TestTerminoComponent } from './test-termino/test-termino.component';
import { environment } from 'src/environments/environment';
import { ItemTestComponent } from './test-termino/itemtest/itemtest.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { CourseComponent } from './course/course.component';
import { ItemcourseComponent } from './course/itemcourse/itemcourse.component';
import { HomeComponent } from './home/home.component';
import { MentionsLegalesComponent } from './mentions-legales/mentions-legales.component';

@NgModule({
  declarations: [
    AppComponent,
    TestTerminoComponent,
    ItemTestComponent,
    CourseComponent,
    ItemcourseComponent,
    HomeComponent,
    MentionsLegalesComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    AngularFireModule.initializeApp(environment.firebase),
   // AngularFireDatabaseModule // for database
   HttpClientModule,
   CountdownModule,
   NgbModule,
   Ng2SearchPipeModule,
   NgxPaginationModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
