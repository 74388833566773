import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { TerminoData } from 'src/app/models/terminoData.model';
import { TerminoServiceService } from 'src/app/termino-service.service';



@Component({
  selector: 'app-itemtest',
  templateUrl: './itemTest.component.html',
  styleUrls: ['./itemTest.component.scss']
})
export class ItemTestComponent implements OnInit {

  @Input() testItem: TerminoData;
  @Input() indexE: number;
  @Input() showResponse:boolean=false;
  @Input() disableInput:boolean;
  @Input() part:number;
  @Input() nbQuest:number;
  showIcon1:boolean=false;
  showIcon2:boolean=false;
  showIcon3:boolean=false;

  correctResponse:boolean=false;
  parentWrap : any;
  el:string = '';
  elResponse :string = "";
  field = '';
  textValue:string="";
  resultCheck :boolean;
  
  points:number=0;
 @Output() note = new EventEmitter<number>() ;


  constructor( private  terminoService: TerminoServiceService) { }

  ngOnInit(): void {
  }

  onKey(event: any) {
    this.field = event.target.value;
    this.resultCheck = this.terminoService.checkResponse(this.textValue, this.elResponse);
    
    if (this.resultCheck){
      this.disableInput=true;
      this.correctResponse=true;
      this.showIcon1 = true;
      if (this.nbQuest === 10){
      this.points = this.points+2;
      }else{
        this.points++;
      }
      this.note.emit(this.points);
      return false;
    }


  }

  activeField(wrap){
    this.el = wrap.target.getAttribute('data-target');
    this.elResponse = document.querySelector('#' + this.el +" .help").textContent;
    document.getElementById(this.el).classList.add('active');
    this.showIcon2 = false;
    this.showIcon3 = true;

  }

  removeField() {
    document.getElementById(this.el).classList.remove('active');
    this.showIcon3 = false;

  }



}
