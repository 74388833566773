<div class="home">
 
 <div class="jumbotron d-flex align-items-center">
    <div class="container text-center">
      
      <div class="row justify-content-md-center">
        <div class="col-lg-6">
          <h1 class="display-4 custom-h1 mb-3">{{title}}</h1>
          <div class="svg">
            <svg version="1.1" class="heart"  xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
            viewBox="0 0 400.45 327.84" style="enable-background:new 0 0 400.45 327.84;" xml:space="preserve">
         <style type="text/css">
           .st0{fill:#D7443E;}
         </style>
         <g>
           <path class="st0" d="M289.56,3.53c58.74,0.29,106.29,47.84,106.58,106.58c0,107.62-195.92,214.2-195.92,214.2
             S4.31,216.16,4.31,110.11C4.31,51.25,52.02,3.53,110.89,3.53l0,0c36.03-0.28,69.72,17.84,89.34,48.07
             C219.98,21.52,253.58,3.44,289.56,3.53z"/>
         </g>
         </svg><svg version="1.1" class="heartbeat" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
         viewBox="0 0 713 278.73" style="enable-background:new 0 0 713 278.73;" xml:space="preserve">
      <style type="text/css">
        .st1{fill:none;stroke:#000000;stroke-width:6;stroke-linejoin:round;stroke-miterlimit:10;}
      </style>
      <polyline class="st1" points="0,143.26 261.04,143.26 276.63,115.99 292.21,205.6 327.28,3 350.66,275.73 377.93,112.09 
        393.51,197.81 412.99,143.26 713,143.26 "/>
      </svg>
    </div>
          <p class="text-muted mb-4"><span class="highlight-word">Petite application web permettant d'apprendre et tester</span> ses connaissances en terminologie pour les étudiants à l'IFSI.</p>
          <span class="d-inline-block">
            <a class="btn btn-primary btn-lg m-1" role="button" routerLink="../test"  >Commencer le test</a>
            <a class="btn btn-secondary btn-lg m-1" role="button" routerLink="../cours" >Apprendre le cours</a>
          </span>
          
        </div>


      </div>  
   
      </div>
    </div>
  </div>