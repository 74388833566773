import { Injectable } from '@angular/core';
import * as firebase from 'firebase';
import { TerminoData } from './models/terminoData.model';
import {alphabet} from 'src/app/models/alphabet.var';

@Injectable({
  providedIn: 'root'
})
export class TerminoServiceService {

  course: TerminoData[] = [];

  constructor() {
  }



getCourse() {
  return firebase.database().ref('cours').once('value').then(function(snapshot) {
    return snapshot.val();
  });
}


generateNb(nbQ, nbMax) {
let tab : Array<number> = [];

  for (var i=0 ; i<nbQ ; i++) {
    var x = Math.floor((Math.random() * nbMax) + 1);
     if (tab.indexOf(x) === -1) {
          tab.push(x);     
     }  
     else if (tab.indexOf(x) > -1) {
         // console.log(x + ' existe déjà dans le this.tableau.');
          i--;
      }
  }
  return tab;

}

checkResponse(compar1, compar2){

  compar1 = compar1.toLowerCase();
  compar1 = this.replaceDiacritics(compar1);
  compar1 = this.cleanText(compar1);
  compar2 = compar2.toLowerCase();
  compar2 = this.replaceDiacritics(compar2);
  compar2 = this.cleanText(compar2);
  //console.log(compar2 + " !===  " + compar1);
  //console.log(compar1 === compar2);

  return (compar1 === compar2);

}

/* supprimer les accents */
replaceDiacritics(str:string) {
  for (let letter in alphabet) {
    str = str.replace(alphabet[letter], letter);
  }
  return str;
};


/**********Verification  les réponses ************/ 

/* supprimer les parenthèse et espaces */

cleanText(str:string){

let clean = (str.match(/\(.[^(]*\)/g)) ? (str.replace(/[()]/g,'')) : str;

return clean.replace(/\s+/g, '');
}

commentaireTest(note : number){

  if (note<10){
   return "C'est pas encore ça, continue de réviser !";
    } 
  else if (note===10) {
    return "Juste la moyenne, je sais que tu peux mieux faire";
  }
  else if (note>10 && note<15) {
   return "Hum, assez bien, mais tu peux faire mieux !";
  }  
  else if (note>=15 && note<18) {
    return "Très bien !";
  }
  else {
    return "Parfait, tu es trop fort";
    }
   
  }
  

}
