import { Component, OnInit, Input } from '@angular/core';
import { TerminoData } from 'src/app/models/terminoData.model';
import { TerminoServiceService } from 'src/app/termino-service.service';

@Component({
  selector: 'app-itemcourse',
  templateUrl: './itemcourse.component.html',
  styleUrls: ['./itemcourse.component.scss']
})
export class ItemcourseComponent implements OnInit {


  @Input() coursItem: TerminoData;
  @Input() indexE: number;

  correctResponse:boolean=false;
  parentWrap : any;
  el:string = '';
  elResponse :string = "";
  field = '';
  textValue:string="";
  resultCheck :boolean;



  constructor( private  terminoService: TerminoServiceService) { }

  ngOnInit(): void {
  }


}
