
<div class="list-group-item" id="{{'id-'+testItem.Id}}" *ngIf="part===1" [class.success]="correctResponse" >
<div class="form-group deft">

    <label class="" for="element{{testItem.Id}}">{{indexE + 1}}) {{testItem.Nom}} </label>
    
    <div class="inputDiv" >
        <span class="ti ti-check" [class.d-none]="!showIcon1"></span><span  class="ti ti-close" [class.d-none]="!showIcon2 && !showResponse"></span><span class="ti ti-pencil" [class.d-none]="!showIcon3 "></span>
        <input type="text" class="form-control deftinput" [attr.data-target]="'id-' + testItem.Id" name="element{{testItem.Id}}" [disabled]="disableInput" (focus)="activeField($event)" (blur)="removeField()" (keyup)="onKey($event)" [(ngModel)]="textValue" id='element{{testItem.Id}}'>
    </div>
<div [class.d-none]="!showResponse && !correctResponse">
    <p *ngIf="!correctResponse; else goodAnswerBlock" class="help text-muted" >{{testItem.Def}}</p>
    <ng-template #goodAnswerBlock>Bonne réponse</ng-template>
</div>
</div>
</div>


<div class="list-group-item" id="{{'id-'+testItem.Id}}" *ngIf="part===2" [class.success]="correctResponse" >
<div class="form-group deft">
    <label class="" for="element{{testItem.Id}}">{{indexE + 1}}) <span class="label-deft">{{testItem.Def }}</span></label>
    <div class="inputDiv" >
        <span class="ti ti-check" [class.d-none]="!showIcon1"></span><span  class="ti ti-close" [class.d-none]="!showIcon2 && !showResponse"></span><span class="ti ti-pencil" [class.d-none]="!showIcon3 "></span>
        <input type="text" class="form-control deftinput" [attr.data-target]="'id-' + testItem.Id" name="{{'id-'+testItem.Id}}"  [disabled]="disableInput" id='element{{testItem.Id}}' (focus)="activeField($event)" (blur)="removeField()" (keyup)="onKey($event)" [(ngModel)]="textValue">
    </div>
    <div [class.d-none]="!showResponse && !correctResponse">
        <p *ngIf="!correctResponse; else goodAnswerBlock" class="help text-muted" >{{testItem.Nom }}</p>
        <ng-template #goodAnswerBlock>Bonne réponse</ng-template>
    </div>
</div>
</div>