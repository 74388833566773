import { Component, OnInit } from '@angular/core';
import { TerminoData } from '../models/terminoData.model';
import { TerminoServiceService } from '../termino-service.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-course',
  templateUrl: './course.component.html',
  styleUrls: ['./course.component.scss']
})
export class CourseComponent implements OnInit {


  title=" Cours";

  coursItems : TerminoData[];
  indexE : number;
  tab:Array<number>=[];
  viewAble:boolean=true;
  showLoader:boolean=false;
  searchText:string;
  p:number;

  loading:boolean=false;
  note : number = 0;

  constructor(
    private  terminoService: TerminoServiceService, 
    private router : Router
    ) {
    

   }

 
  ngOnInit() {
    this.getCourseItemsFiltered();

  }
  firstPage(event: any){
    return this.p !== 1 ? this.p = 1 : false;
  }


getCourseItemsFiltered() {
  this.terminoService.getCourse().then((val)=>{
    //console.log(val);
    this.p = 1;
    return this.coursItems=val;
  }).catch((e)=>{
    console.log(e);
  }).then(()=>{
    this.showLoader = true;
  }
);

}






}
