import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { TestTerminoComponent } from './test-termino/test-termino.component';
import { CourseComponent } from './course/course.component';
import { HomeComponent } from './home/home.component';
import { MentionsLegalesComponent } from './mentions-legales/mentions-legales.component';



const routes: Routes = [
  { path: '', redirectTo: '/home', pathMatch: 'full' },
  { path: 'home', component: HomeComponent },
  { path: 'test', component: TestTerminoComponent },
  { path: 'cours', component: CourseComponent },
  { path: 'mentions-legales', component: MentionsLegalesComponent },
  { path: '**', redirectTo: '/test' }
];


@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
