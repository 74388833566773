import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { TerminoServiceService } from '../termino-service.service';
import { TerminoData } from '../models/terminoData.model';
import { Router } from '@angular/router';
import { NgForm } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CountdownComponent } from 'ngx-countdown';
import {NgbNavConfig} from '@ng-bootstrap/ng-bootstrap';


@Component({
  selector: 'app-test-termino',
  templateUrl: './test-termino.component.html',
  styleUrls: ['./test-termino.component.scss'],
  providers: [NgbNavConfig]
})
export class TestTerminoComponent implements OnInit {

  messageFin="Correction";
  
  testItems : TerminoData[];
  indexE : number;
  tab:Array<number>=[];
  myform:NgForm;
  disableInput:boolean = false;
  correction:boolean=false;
  showResponse:boolean=false;
  commentaire : string = "";
  @ViewChild('countdown') counter: CountdownComponent;
  @ViewChild('content') content: any;
  viewAble:boolean=true;
  nbQuest : number= 10;
  showLoader:boolean=false;
  filtered1:TerminoData[]=[];
  filtered2:TerminoData[]=[];
  isActive:boolean=true;
  soonTimeUp:boolean=false;
  checkedTimer:boolean=false;

  

  loading:boolean=false;
  note : number = 0;

  constructor(
    private  terminoService: TerminoServiceService, 
    private router : Router,
    private modalService: NgbModal,
    config: NgbNavConfig
    ) {
          // customize default values of navs used by this component tree
    config.destroyOnHide = false;
    config.roles = false;
    

   }

 
  ngOnInit() {
    this.getCourseItemsFiltered();

  }


getCourseItemsFiltered() {
  this.terminoService.getCourse().then((val)=>{

    return this.testItems=val;
  }).catch((e)=>{
    console.log(e);
  }).then(()=>{

  this.filterQuest();

    this.showLoader = true;
  }
);

}


filterQuest(){
  let nbMax = this.testItems.length-1;
  this.tab = this.terminoService.generateNb(this.nbQuest, nbMax);
  let tabLength = this.tab.length/2;
  for (let i = 0; i < this.tab.length;i++){
    if (i < tabLength*1){
      this.filtered1.push(this.testItems[this.tab[i]]);
    }else if(i >= tabLength*1 && i < tabLength*2){
    this.filtered2.push(this.testItems[this.tab[i]]);
  }
  this.showLoader = true;
}
}

startCountdown(ev){
if(ev.currentTarget.checked){
  this.counter.begin();
  this.checkedTimer=true;
} else{
  this.counter.pause();
}

}

nbQuestFunct(nb){
  if(nb===20){
  this.counter.config.leftTime = 720 ;
  this.checkedTimer=false;
  }
  else{
    this.counter.config.leftTime = 360;
    this.checkedTimer=false;
  }
  this.nbQuest=nb;
}

timesUp(event) { 
  if (event.action == "notify"){
    this.soonTimeUp = true;
  }
  if (event.action == "done") { 
    console.log("Fini"); 
    this.open(this.content);
    this.messageFin="Temps écoulé";
  } 
}

open(content) {
  const modalRef = this.modalService.open(content);
  this.showResponse=true;
  this.disableInput=true;
  this.checkedTimer=false;
  this.correction = true;
  this.counter.pause();
  this.commentaire = this.terminoService.commentaireTest(this.note);
  

 // console.log(document.querySelectorAll(".list-group-item:not(.success)"));

}

noteCounter(note){
  this.note = this.note + note;
}


refreshTest(event) {
  this.tab = [];
  this.filtered1 = [];
  this.filtered2 = [];
  this.showLoader = false;
  this.showResponse=false;
  this.disableInput=false;
  this.correction = false;
  this.checkedTimer=false;
  this.note = 0;
  this.filterQuest();
  event.preventDefault();
  this.router.navigateByUrl('/test', {skipLocationChange: true})
    .then(() => {
      this.router.navigate(['/test']);

    });
}






}
